import React from "react";
import { createRoot } from 'react-dom/client';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { addLocale, useLocale as doUseLocale } from 'ttag';
import { getLocale, configureLocaleSwitcher, configureLocale, PROD } from "./util";
import * as serviceWorker from "./serviceWorker";

import "jquery/dist/jquery"; //TODO: Delete jquery when bootstrap v5 is released
import "bootstrap/dist/js/bootstrap.bundle";

// Select bootstrap base depending on mode
import "./resources/custom.scss";

// Import flag icons, use with class="flag-icon flag-icon-es"
// Add "flag-icon-squared" to square it
import 'flag-icon-css/sass/flag-icon.scss';

// Import 'saveAs' global
import 'file-saver';

import App from "./App";
import "./index.css";

import "./resources/common/fonts/General/Gotham/gotham.css";
import "./resources/common/fonts/General/Calibri/calibri.css";
import "./resources/common/fonts/General/HelveticaNarrow/helvetica-narrow.css";
import "./resources/common/fonts/General/Arial/arial.css";
import "./resources/common/fonts/General/CourierNew/courier-new.css";

import "./resources/common/fonts/Japanese/Meiryo/meiryo.css";
import "./resources/common/fonts/Japanese/YuGothic/yu-gothic.css";

import "./resources/common/fonts/Hebrew/Alef/alef.css";
import "./resources/common/fonts/Hebrew/Asakim/asakim.css";
import "./resources/common/fonts/Hebrew/Assistant/assistant.css";
import "./resources/common/fonts/Hebrew/DavidLibre/davidlibre.css";
import "./resources/common/fonts/Hebrew/Felix007/felix007.css";

import "./resources/common/fonts/Arabic/fonts.css";

// Load the languages
configureLocale("gb", {
  "gb": "English", "es": "Español", "pl": "Polski", "nl": "Nederlands", "fr": "Français",
  "al": "Shqip", "jp": "日本語", "it": "Italiano", "de": "Deutsch", "cn": "汉语/漢語"
});
addLocale("es", require("./i18n/es.po.json"));
addLocale("pl", require("./i18n/pl.po.json"));
addLocale("nl", require("./i18n/nl.po.json"));
addLocale("al", require("./i18n/al.po.json"));
addLocale("jp", require("./i18n/jp.po.json"));
addLocale("it", require("./i18n/it.po.json"));
addLocale("de", require("./i18n/de.po.json"));
addLocale("fr", require("./i18n/fr.po.json"));
addLocale("cn", require("./i18n/cn.po.json"));

type RootState = { locale: string };
class Root extends React.Component<{}, RootState>  {
  constructor(props: {}) {
    super(props);
    this.state = { locale: getLocale() };
    configureLocaleSwitcher(this.switchLocale);
  }
  switchLocale = (locale: string) => this.setState({ locale });
  render() {
    const { locale } = this.state;
    console.log("Setting locale to", locale);
    doUseLocale(locale);

    // toast has success, info, warn and error methods and returns an id that can be used with:
    // const id = toast.success("message", {...otherToastOptions})
    // toast.dismiss(id?): Dismisses the toast with the id or all of them
    // toast.update(id, {render: "message", type: toast.TYPE.INFO, ...otherToastOptions})
    // NOTE: render "message" can be either a string or a react component
    return <>
      {/* <React.StrictMode> */}
      <App />
      <ToastContainer position={toast.POSITION.TOP_CENTER} theme="dark" autoClose={5000} hideProgressBar />
      {/* </React.StrictMode> */}
    </>;
  }
}

createRoot(document.getElementById('root')!).render(<Root />);

const forceDisableSW = true;
// Learn more about service workers: https://bit.ly/CRA-PWA
if (PROD && !forceDisableSW) { serviceWorker.register(); }
else { serviceWorker.unregister(); }
