import React, { useState, useRef, useEffect } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import { SubmitButton, Modal, Progress } from "./shared";
import { setToken, fetchPost, handler, friendlyPassScore } from "../util";

const AccountInfoComponent: React.FC<{ name: string, email: string }> = (props) => {
  const modalId = "accountModal";

  const [name, setName] = useState(props.name);
  const [email, setEmail] = useState(props.email);
  const [pass, setPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");

  const passwordCheck = useRef<(pass: string, inputs: string[]) => number>();
  useEffect(() => {
    import("zxcvbn")
      .then(({ default: zxcvbn }) => {
        passwordCheck.current = (p, i) => zxcvbn(p, i).score;
      });
  }, []);
  const passScore = (passwordCheck.current?.(newPass, [email])) || 0;

  const strongPass = passScore >= 2;
  const passField = document.getElementById("accountNewPass") as HTMLInputElement;
  passField?.setCustomValidity(strongPass || !newPass ? "" : t`Password is not strong enough`);

  const passwordsSame = newPass === newPassConfirm;
  const confirmPassField = document.getElementById("accountNewPassConfirm") as HTMLInputElement;
  confirmPassField?.setCustomValidity(passwordsSame ? "" : t`The passwords don't match`);

  const submit = handler(async (e: React.FormEvent<HTMLFormElement>) => {
    const body = JSON.stringify({ name, email, pass, newPass, newPassConfirm });
    const res = await fetchPost("/api/user/profile", body);

    if (res.ok) {
      toast.success(t`User data changed successfully`);
      document.getElementById(modalId)!.click();

    } else {
      if (res.status === 401) {
        return toast.error(t`The current password is invalid`);

      } else if (res.status === 422) {
        const json = await res.json();
        const { errors } = json;

        if (errors["newPass"] && errors["newPass"].indexOf("validation.min.string") !== -1) {
          return toast.error(t`Password is not strong enough`);
        }

        if (errors["newPassConfirm"] && errors["newPassConfirm"].indexOf("validation.same") !== -1) {
          return toast.error(t`The passwords don't match`);
        }
      }

      return toast.error(t`Unknown error`);
    }
  });

  const deleteAccount = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const passwordField = document.getElementById("accountPass")! as HTMLInputElement;

    if (passwordField.reportValidity() &&
      window.confirm(t`Are you sure you want to continue? This step cannot be reversed`)) {

      const body = JSON.stringify({ pass });
      const res = await fetchPost("/api/user/delete", body);
      if (res.ok) {
        setToken();
        alert(t`Account deleted correctly`);
        window.location.reload();
      } else if (res.status === 401) {
        toast.error(t`The current password is invalid`);
      } else {
        toast.error(t`Unknown error`);
      }
    }
  }

  return <Modal modalId={modalId} title={t`Modify account`}>
    <form action="." onSubmit={submit} method="post" className="p-1">
      <div className="form-row">
        <div className="col">
          <label htmlFor="accountName">{t`Name`}</label>
          <input type="text" autoComplete="name" required className="form-control" name="name"
            id="accountName" value={name} onChange={e => setName(e.target.value)} />
        </div>

        <div className="col">
          <label htmlFor="accountMail">{t`Email address`}</label>
          <input type="email" autoComplete="email" required className="form-control" name="email"
            id="accountMail" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
      </div>

      <hr className="mt-4 mb-1" />
      <small className="d-block mb-2 text-muted">{t`Fill the following two fields to change the password`}</small>

      <div className="form-row">
        <div className="col">
          <label htmlFor="accountNewPass">{t`New password`}</label>
          <input type="password" autoComplete="new-password" className="form-control" name="newPass"
            id="accountNewPass" value={newPass} onChange={e => setNewPass(e.target.value)} />
        </div>

        <div className="col">
          <label htmlFor="accountNewPassConfirm">{t`Confirm new password`}</label>
          <input type="password" autoComplete="new-password" className="form-control" name="newPassConfirm"
            id="accountNewPassConfirm" value={newPassConfirm} onChange={e => setNewPassConfirm(e.target.value)} />
        </div>
      </div>

      {!!newPass && <div className="mt-4" style={{ width: "60%" }}>
        <Progress percent={Math.max(passScore * 25, 7)} />
        <small className="text-muted">{t`Password Strength:` + " " + friendlyPassScore(passScore)}</small>
      </div>}

      <hr className="mt-3 mb-1" />
      <small className="d-block mb-2 text-muted">{t`This field is required to save any changes or delete the account`}</small>

      <div className="form-group">
        <label htmlFor="accountPass">{t`Current password`}</label>
        <input type="password" autoComplete="current-password" required className="form-control" name="pass"
          id="accountPass" value={pass} onChange={e => setPass(e.target.value)} />
      </div>

      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-danger"
          onClick={deleteAccount}>{t`Delete account`}</button>
        <SubmitButton text={t`Save changes`} loadingText={t`Loading...`} enabled />
      </div>
    </form>
  </Modal>;
}

export default AccountInfoComponent;
