import { createToken, Lexer, EmbeddedActionsParser, TokenType, IRecognitionException, ILexingError } from "chevrotain";
import { PROD } from "./util";

const DEBUG = !PROD;

/*************
 *   LEXER   *
 *************/
const WhiteSpace = createToken({ name: "WhiteSpace", pattern: /\s+/, group: Lexer.SKIPPED });
const Dash = createToken({ name: "Dash", pattern: /-/ });
const Comma = createToken({ name: "Comma", pattern: /,/ });
const Identifier = createToken({ name: "Identifier", pattern: /[a-zA-Z0-9]+/ });
const roomNumbersTokens = [WhiteSpace, Dash, Comma, Identifier];
const roomNumbersLexer = new Lexer(roomNumbersTokens, { ensureOptimizations: true, skipValidations: !DEBUG });

/**************
 *   PARSER   *
 **************/
class RoomNumbersParser extends EmbeddedActionsParser {
    constructor(tokens: TokenType[]) {
        super(tokens, { maxLookahead: 1, skipValidations: !DEBUG });
        this.performSelfAnalysis();
    }

    public matchRules = this.RULE("matchRules", () => {
        //const set = new Set<number>();
        const arr: string[] = [];

        // Accept a possible initial comma, weird, but okay
        this.OPTION(() => this.CONSUME(Comma));

        // Any number of matchRules, with possible comma at the end
        this.AT_LEAST_ONE(() => {
            const result = this.SUBRULE(this.matchRule)
            this.OPTION2(() => this.CONSUME2(Comma));

            // ACTION so it doesn't run in the record phase
            this.ACTION(() => {
                const [start, end, padSize] = result;
                if (isNaN(start) || isNaN(end) || end < start) { return }
                for (let i = start; i <= end; i++) {
                    //set.add(i);
                    arr.push(i.toString().padStart(padSize, "0"));
                }
            });
        });

        // Convert to array and sort
        // return [...set.keys()].sort((a, b) => a - b);
        return arr;
    });

    private matchRule = this.RULE("matchRule", () => {
        const startStr = this.CONSUME1(Identifier).image;
        const start = parseInt(startStr);

        const end = this.OPTION(() => {
            this.CONSUME(Dash);
            return parseInt(this.CONSUME2(Identifier).image);
        });

        // Avoid processing while we are at the recording phase
        return this.RECORDING_PHASE ? [NaN, NaN, 0] : [start, end ?? start, startStr.length];
    });
}

const roomNumbersParser = new RoomNumbersParser(roomNumbersTokens);

export function parseRoomNumbers(text: string): [string[], boolean, ILexingError[], IRecognitionException[]] {
    try {
    const { tokens, errors } = roomNumbersLexer.tokenize(text);

    // "input" is a setter which will reset the parser's state.
    roomNumbersParser.input = tokens;
    const value = roomNumbersParser.matchRules(); // Name of the rule

    return [value, errors.length > 0 || roomNumbersParser.errors.length > 0, errors, roomNumbersParser.errors];
    } catch (e) {
        console.log("Error parsing room numbers", e);
        return [[], true, [], []];
    }
}
