import { DesignData, getModelNameGlassOnly, Rank, SpecsMap, UserData } from "../types";
import React, { Component } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import {
  fetchGet, fetchPost, handler, setToken, filenamify,
  loading, getUsrThumbUrl, friendlyDate, pullQuery, boxShadow, adminInpersonate, loadingText, parseJson
} from "../util";
import ModelSVGComponent, { Formats } from "./ModelSVG";
import { FaUserNinja } from "react-icons/fa";
import { parseRoomNumbers } from '../parser';

type AdminPageProps = {
  updateHandler: (register?: boolean) => void,
  rank: Rank,
}

enum TabType {
  Designs = -1,

  All = 0,
  Validation = 1,
  Printing = 2,

  Users = 10,
}

const loginAsUser = (id: string) => () => {
  fetchPost("/api/admin/loginas", JSON.stringify({ id }))
    .then(p => p.json())
    .then(j => {
      adminInpersonate(j.token);
      window.location.reload();
    })
};

const isOrderTab = (type: TabType) => [TabType.All, TabType.Printing, TabType.Validation].includes(type);

type AdminPageState = {
  query?: string,

  // Only one of these two should be filled
  tabType: TabType,
  designs: DesignData[],
  orders: { [project_id: string]: DesignData[] },
  users: UserData[],

  downloadDesign?: DesignData,
  justReturn: boolean,
  format: Formats,

  hotelRoomIndex?: number,

  downloadFinished?: (svg: Blob | string) => void,
}

export default class AdminPageComponent extends Component<AdminPageProps, AdminPageState> {
  canvasRef = React.createRef<ModelSVGComponent>();

  constructor(props: AdminPageProps) {
    super(props);

    const tabType = props.rank === "admin-validate" ? TabType.Validation : TabType.Printing;
    const query = pullQuery().get('order') || undefined;

    this.state = {
      designs: [], orders: {}, users: [],
      justReturn: false, format: "svg", tabType, query
    };
  }

  deleteHandler = (design: DesignData) => {
    fetchPost(`/api/admin/design/${design.id}/delete`)
      .then(p => this.goDesigns());
    // TODO: Handle error
  }

  logoutHandler = (e?: React.SyntheticEvent) => {
    e && e.preventDefault();
    setToken(undefined);
    this.props.updateHandler(false);
  }

  reload = (tabType: TabType = TabType.Designs) => {
    const endLoad = () => loading(false);
    if (isOrderTab(tabType)) {
      this._reload("/api/admin/order", tabType,
        p => this.setState({ tabType, orders: p, designs: [], users: [] }, endLoad));
    } else if (tabType === TabType.Designs) {
      this._reload("/api/admin/design", tabType,
        p => this.setState({ tabType, designs: p, orders: {}, users: [] }, endLoad));
    }
    else if (tabType === TabType.Users) {
      this._reload("/api/admin/user", tabType,
        p => this.setState({ tabType, designs: [], orders: {}, users: p }, endLoad));
    }
  }

  goDesigns = () => this.reload(TabType.Designs);
  goOrders = (type: TabType) => this.reload(type);
  goUsers = () => this.reload(TabType.Users);

  _reload = (url: string, type: TabType, action: (input: any) => void) => {
    const { query } = this.state;
    let queryUrl = query ? `&q=${encodeURIComponent(query)}` : "";

    fetchGet(`${url}?order=${type}${queryUrl}`)
      .then(res => {
        if (res.ok) {
          res.json().then(action);
        } else {
          toast.error(t`Unknown error`);
          this.logoutHandler();
        }
      });
  }

  componentDidMount() {
    this.goOrders(this.state.tabType);
  }

  search = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({ query: query }, () => {
      this.reload(this.state.tabType);
    });
  }

  download = (downloadDesign: DesignData, format: Formats, justReturn?: boolean, hotelRoomIndex?: number) => {
    loading(true);
    this.setState({ downloadDesign, format, justReturn: justReturn || false, hotelRoomIndex });
  }

  render() {
    const { downloadDesign, format, justReturn, designs, users, hotelRoomIndex,
      orders, tabType: type, query, downloadFinished: df } = this.state;

    const active = (b: boolean) => b ? "active" : "";

    const designsActive = active(type === TabType.Designs);
    const ordersActive = active(isOrderTab(type));
    const usersActive = active(type === TabType.Users);

    return (
      <div className="h-100vh d-flex flex-column">
        <nav className="navbar sticky-top navbar-expand navbar-dark bg-dark" style={{ boxShadow }}>
          <a className="navbar-brand" href="#pro"
            onClick={handler(this.goDesigns)}>Admin</a>
          <ul className="navbar-nav mr-auto">
            <li className={`nav-item ${designsActive}`}>
              <a className="nav-link" href="#pro"
                onClick={handler(this.goDesigns)}>{t`All designs`}</a>
            </li>
            <li className={`nav-item dropdown ${ordersActive}`}>
              <a className="nav-link dropdown-toggle" href="#ord" role="button" data-toggle="dropdown" >
                {t`Orders` + ": " + ((type === TabType.All) ? t`All` : (type === TabType.Validation) ? t`Validation` : t`Print`)}
              </a>
              <div className="dropdown-menu">
                <a className={`dropdown-item ${active(type === TabType.All)}`}
                  href="#all" onClick={handler(this.goOrders, TabType.All)}>{t`All`}</a>
                <div className="dropdown-divider"></div>
                <a className={`dropdown-item ${active(type === TabType.Validation)}`}
                  href="#val" onClick={handler(this.goOrders, TabType.Validation)}>{t`Validation`}</a>
                <a className={`dropdown-item ${active(type === TabType.Printing)}`}
                  href="#pri" onClick={handler(this.goOrders, TabType.Printing)}>{t`Print`}</a>
              </div>
            </li>
            <li className={`nav-item ${usersActive}`}>
              <a className="nav-link" href="#usr"
                onClick={handler(this.goUsers)}>{t`All users`}</a>
            </li>
          </ul>

          <form className="form-inline mr-4" onSubmit={handler()}>
            <input className="form-control" placeholder={t`Search...`}
              onChange={this.search} value={query} type="search" />
          </form>
          <a className="nav-item" href="#a3" onClick={this.logoutHandler}>{t`Log out`}</a>
        </nav>

        <div className="row justify-content-center h-100 m-0 of-y-auto">
          <div className="col-8 mt-4">
            {designsActive && designs.map((d, i) =>
              <DesignCard design={d} key={d.id} download={this.download} isFirst={i === 0} />
            )}

            {ordersActive && Object.entries(orders).map(([pid, designs]) =>
              (designs as any).length > 0 && <OrderCard designs={designs} orders={orders} key={pid} pid={pid} tabType={type}
                reload={() => this.goOrders(type)} download={this.download}
                setDownloadFinished={df => this.setState({ downloadFinished: df })} />
            )}

            {usersActive && users.map(u => <UserCard key={u.id} user={u} />)}

            <div id="bottom-bar-spacer" />
            {((designsActive && designs.length <= 0) || (ordersActive && Object.keys(orders).length <= 0)) &&
              <div className="text-light text-center">{t`There are currently no designs/orders`}</div>}
          </div>
        </div>

        <ModelSVGComponent ref={this.canvasRef} format={format} pngWidth={2000} hotelRoomIndex={hotelRoomIndex}
          justReturn={justReturn} height={0} design={downloadDesign} forPrinting={true}
          downloadFinished={svg => this.setState({ downloadDesign: undefined },
            () => df?.(svg))} />
      </div>
    );
  }
}

type DesignCardProps = {
  design: DesignData,
  isFirst: boolean,
  download: (p: DesignData, format: Formats) => void,
}

function DesignCard(props: DesignCardProps) {
  const { design, download, isFirst } = props;
  const { name, project_name, model, glass_only, user_id, user_name, user_email, id, updated_at } = design;

  const click = (format: Formats) => () => download(design, format);

  const model_name = getModelNameGlassOnly(model, glass_only);

  return (
    <div className="card mt-2 w-100" style={{ height: 160 }}>
      <div className="card-body row">
        <div className="col-2">
          <img src={getUsrThumbUrl(id, updated_at || "")} height="120px" decoding="async" loading="lazy"
            className={`d-block m-auto ${isFirst ? "hover-zoom-down" : "hover-zoom"}`} alt="..." style={{ boxShadow }} />
        </div>
        <div className="col-10">
          <div className="float float-right" style={{ width: 120 }}>
            <button type="button" className="btn btn-sm btn-secondary mb-1 w-100" onClick={click("svg")}>.SVG RGB</button>
            {/*<br />
            <button type="button" className="btn btn-sm btn-secondary mb-1 w-100" onClick={click("svg12")}>.SVG CMYK</button>*/}
            <br />
            <button type="button" className="btn btn-sm btn-secondary mb-1 w-100" onClick={click("png")}>.PNG</button>
            {/* <br />
            <button type="button" className="btn btn-sm btn-outline-primary w-100" onClick={click("eps")}>.EPS</button> */}
          </div>

          <h5 className="card-title">{`${project_name} / ${name}`}</h5>
          <h6 className="card-subtitle mb-2">
            <a className="text-muted" href={`mailto:${user_email}`}>{`${user_email} (${user_name})`}</a>
            <FaUserNinja className="text-muted ml-3" style={{ verticalAlign: -2.5, cursor: "pointer" }}
              title={t`Login as user`} onClick={loginAsUser(user_id!)} />
          </h6>

          <p className="small mb-1">{(SpecsMap[model] || { name: "N/A" }).name + (glass_only ? " " + t`(glass only)` : "") + ` [${model_name}]`}</p>

          <p className="small text-muted" title={updated_at}>
            {t`Last updated` + ": " + updated_at?.split("T")[0] + " (" + friendlyDate(updated_at) + ")"}</p>
        </div>
      </div>
    </div>
  );
}

type OrderCardProps = {
  pid: string,
  designs: DesignData[],
  orders: { [pid: string]: DesignData[] },
  tabType: TabType,
  reload: () => void,
  download: (p: DesignData, format: Formats, justReturn?: boolean, hotelRoomIndex?: number) => void,
  setDownloadFinished: (df: (svg: Blob | string) => void) => void,
}
function OrderCard(props: OrderCardProps) {
  const { pid, designs, orders, tabType, reload, download, setDownloadFinished } = props;
  const { order_code, order_status, user_email, user_name, project_name, user_id } = designs[0];

  const [newOrderCode, setNewOrderCode] = React.useState(order_code);

  const lastUpdate = designs.reduce(
    (prev, curr) =>
      Math.max(prev, Date.parse(curr.updated_at || "0")),
    0);

  const changeOrder = async (operation: string) => {
    await fetchPost(`/api/admin/order/${pid}`, JSON.stringify({ operation }))
    toast.success(t`Operation performed correctly`);
    reload();
  }

  const validateOdoo = async (orderCode: string) => {
    const oldOrderCode = designs[0]?.order_code ?? orderCode;

    // Copy the current projects designs
    let combinedDesigns = [...designs];

    // If this is a multi part project...
    if (/\/\d\d$/.test(orderCode)) {
      const orderPrefix = orderCode.split("/")[0] + "/";

      for (let designs of Object.values(orders)) {
        if (designs.length === 0 || !designs[0].order_code?.startsWith(orderPrefix)) {
          continue;
        }

        // We already have this project in 'designs', so don't add it again, we check the old order code because here it's not updated
        if (designs[0].order_code === oldOrderCode) {
          continue;
        }

        console.log("ORDER starts with ", designs[0].order_code);

        combinedDesigns.push(...designs);
      }
    }

    const result = await downloadAllOdoo(combinedDesigns);
    const formData = new FormData();
    for (let model in result) {
      console.log("Generating model " + model);
      const zipBlob = await result[model].generateAsync({ type: "blob" });
      formData.append("zip_" + model, zipBlob);
    }
    console.log("Generating model ", formData);
    loadingText("Subiendo");

    try {
      const res = await fetchPost(`/api/admin/order/${pid}/validate?order_code=${encodeURI(orderCode)}`, formData);
      const data = await res.json();
      if (res.ok) {
        if (data.differences && Object.keys(data.differences).length > 0) {
          toast.error("Error: " + JSON.stringify(data.differences, null, 2));

        } else {
          toast.success(t`Order validated correctly`);
          reload();
        }
      } else {
        toast.error("Error: " + data.error);
      }
    } catch (e) {
      toast.error("Error: " + e);
    }
    loading(false);
  }

  const downloadAllOdoo = async (combinedDesigns: any[]) => {
    const { default: JSZip } = await import("jszip");

    return new Promise<{ [model: string]: typeof JSZip }>((resolve, reject) => {
      const totalDesigns: [DesignData, number, string][] = [];

      for (let i = 0; i < combinedDesigns.length; i++) {
        const specs = SpecsMap[combinedDesigns[i].model];
        if (specs.extra === "vh") {
          const numbers = parseJson(combinedDesigns[i].order_extra)?.numbers;
          if (!numbers) continue;

          const [rooms, isError] = parseRoomNumbers(numbers);
          if (isError) continue;

          for (let j = 0; j < rooms.length; j++) {
            totalDesigns.push([combinedDesigns[i], j, rooms[j]]);
          }

        } else {
          totalDesigns.push([combinedDesigns[i], -1, ""]);
        }
      }

      const num = totalDesigns.length;
      const svgs: { design: DesignData, svg: Blob | string, hotelRoomIndex: number, hotelRoomValue: string }[] = [];

      const getOne = async (i: number) => {
        // Once all designs are processed, save them all to a zip file
        if (i >= num) {
          const zips: { [model: string]: typeof JSZip } = {};

          svgs.forEach(({ design, svg, hotelRoomIndex, hotelRoomValue }, i) => {
            const name = filenamify(design.name);

            const count = svgs.slice(0, i).filter(s => filenamify(s.design.name) === name && s.hotelRoomIndex === hotelRoomIndex).length;
            const countstr = count ? `(${count})` : "";

            const model_name = getModelNameGlassOnly(design.model, design.glass_only);

            const zname = hotelRoomIndex >= 0 ?
              `${filenamify(order_code ?? project_name ?? "")}_${model_name}__vh${hotelRoomValue}__${name}${countstr}.svg` :
              `${filenamify(order_code ?? project_name ?? "")}_${model_name}__${design.order_quantity || 0}uds__${name}${countstr}.svg`;

            zips[model_name] = zips[model_name] ?? new JSZip();
            zips[model_name].file(zname, svg)
          });

          resolve(zips);
          return;
        }

        // Once a design is done, we save it and start the next one
        setDownloadFinished((svg: Blob | string) => {
          const [d, idx, value] = totalDesigns[i];
          svgs.push({ design: d, svg, hotelRoomIndex: idx, hotelRoomValue: value });

          // Limit the number update to 1 in 5 for performance
          if (i % 5 === 0) loadingText(`${i}/${num}`);

          getOne(i + 1);
        });

        download(totalDesigns[i][0], "svg", true, totalDesigns[i][1]);
      };

      // Get the first design, which will trigger the next steps
      getOne(0);
    });
  }

  const downloadAll = () => {
    const totalDesigns: [DesignData, number, string][] = [];

    for (let i = 0; i < designs.length; i++) {
      const specs = SpecsMap[designs[i].model];
      if (specs.extra === "vh") {
        const numbers = parseJson(designs[i].order_extra)?.numbers;
        if (!numbers) continue;

        const [rooms, isError] = parseRoomNumbers(numbers);
        if (isError) continue;

        for (let j = 0; j < rooms.length; j++) {
          totalDesigns.push([designs[i], j, rooms[j]]);
        }

      } else {
        totalDesigns.push([designs[i], -1, ""]);
      }
    }

    const num = totalDesigns.length;
    const svgs: { design: DesignData, svg: Blob | string, hotelRoomIndex: number, hotelRoomValue: string }[] = [];

    const getOne = async (i: number) => {
      // Once all designs are processed, save them all to a zip file
      if (i >= num) {
        const { default: JSZip } = await import("jszip");
        const zip = new JSZip();
        svgs.forEach(({ design, svg, hotelRoomIndex, hotelRoomValue }, i) => {

          const name = filenamify(design.name);

          const count = svgs.slice(0, i).filter(s => filenamify(s.design.name) === name && s.hotelRoomIndex === hotelRoomIndex).length;
          const countstr = count ? `(${count})` : "";

          const model_name = getModelNameGlassOnly(design.model, design.glass_only);

          const zname = hotelRoomIndex >= 0 ?
            `${model_name}_${filenamify(order_code ?? project_name ?? "")}__vh${hotelRoomValue}__${design.name}${countstr}.svg` :
            `${model_name}_${filenamify(order_code ?? project_name ?? "")}__${design.order_quantity || 0}uds__${name}${countstr}.svg`;

          zip.file(zname, svg)
        });
        const zipBlob = await zip.generateAsync({ type: "blob" })
        window.saveAs(zipBlob, `${filenamify(order_code ?? project_name ?? "")}.zip`);
        loading(false);
        return;
      }

      // Once a design is done, we save it and start the next one
      setDownloadFinished((svg: Blob | string) => {
        const [d, idx, value] = totalDesigns[i];
        svgs.push({ design: d, svg, hotelRoomIndex: idx, hotelRoomValue: value });

        // Limit the number update to 1 in 5 for performance
        if (i % 5 === 0) loadingText(`${i}/${num}`);

        getOne(i + 1);
      });

      download(totalDesigns[i][0], "svg", true, totalDesigns[i][1]);
    };

    // Get the first design, which will trigger the next steps
    getOne(0);
  }

  let buttons: any = false;
  if (order_status === "waiting-validate") {
    buttons = <>
      <input type="text" required className="form-control form-control-sm mb-1" style={{ width: "30%", display: "inline-block" }}
        value={newOrderCode} onChange={e => setNewOrderCode(e.target.value)} />
      <button type="button" className="btn btn-sm btn-primary mb-1" style={{ width: "70%" }}
        onClick={() => newOrderCode && validateOdoo(newOrderCode)}>{t`Validate & Send to printing`}</button>
      <br />
      {/* <button type="button" className="btn btn-sm btn-primary mb-1 w-100" disabled
        onClick={() => changeOrder("print")}>{t`Validate & Send to printing`}</button>
      <br /> */}
      <button type="button" className="btn btn-sm btn-outline-danger w-100"
        onClick={() => changeOrder("cancel")}>{t`Cancel order`}</button>
    </>;
  } else if (order_status === "waiting-printing") {
    buttons = <>
      <button type="button" className="btn btn-sm btn-primary mb-1 w-100"
        onClick={downloadAll}>{t`Download all`}</button>
      <br />
      <button type="button" className="btn btn-sm btn-secondary w-100"
        onClick={() => changeOrder("complete")}>{t`Mark complete`}</button>
    </>;
  } else {
    buttons = <>
      <button type="button" className="btn btn-sm btn-primary mb-1 w-100"
        onClick={downloadAll}>{t`Download all`}</button>
    </>;
  }

  return <div className="card mt-2 w-100">
    <div className="card-body">
      <div className="row">
        <div className="col-7 mb-2">
          <h4 className="card-title" title={order_status}>{t`Project '${project_name}'`}</h4>
          {order_code && <h5 className="card-title">{t`Order '${order_code}'`}</h5>}
          <h6 className="card-subtitle mb-1">
            <a className="text-muted" href={`mailto:${user_email}`}>{`${user_email} (${user_name})`}</a>
            <FaUserNinja className="text-muted ml-3" style={{ verticalAlign: -2.5, cursor: "pointer" }}
              title={t`Login as user`} onClick={loginAsUser(user_id!)} />
          </h6>
          <p className="card-text"><small className="text-muted" title={new Date(lastUpdate).toUTCString()}>
            {t`Last updated` + ": " + new Date(lastUpdate).toISOString().split("T")[0] + " (" + friendlyDate(lastUpdate) + ")"}
          </small></p>
        </div>
        <div className="col-5">
          {buttons}
        </div>
      </div>
      <div className="row d-flex flex-wrap ml-2 pt-1">
        {designs.filter(p => tabType === TabType.All || p.order_quantity)
          .map(p => {
            const specs = SpecsMap[p.model];

            let quantity = p.order_quantity || 0;
            if (specs.extra === "vh" && p.order_extra) {
              quantity = parseRoomNumbers(parseJson(p.order_extra)?.numbers ?? "")?.[0]?.length ?? 0;
            }

            const model_name = getModelNameGlassOnly(p.model, p.glass_only);

            return <div key={p.id} className="mr-3">
              <span className="text-muted text-center d-block mb-0">{p.name}</span>
              <span style={{ fontSize: "60%" }} className="text-muted small text-center d-block mb-0">{specs.name + (p.glass_only ? " " + t`(glass only)` : "")}</span>
              <span style={{ fontSize: "60%" }} className="text-muted small text-center d-block mb-0">{model_name}</span>
              <small className="text-muted text-center d-block mb-1">{t`(${quantity} units)`}</small>
              <img src={getUsrThumbUrl(p.id, p.updated_at || "")} className="d-block m-auto hover-zoom" decoding="async" loading="lazy"
                style={{ maxHeight: 80, maxWidth: "160%", boxShadow }} alt="..." />
              <a className="d-block text-dark text-center small mt-2" onClick={e => { e.preventDefault(); download(p, "svg") }} href="#svg11">.SVG RGB</a>
              {/*<a className="d-block text-dark text-center small" onClick={e => { e.preventDefault(); download(p, "svg12") }} href="#svg12">.SVG CMYK</a>*/}
            </div>;
          })}
      </div>
    </div>
  </div>;
}

type UserCardProps = {
  user: UserData,
}

function UserCard(props: UserCardProps) {
  const { user } = props;
  const { id, name, email } = user;

  return (
    <div className="card mt-2 w-100" style={{ height: 100 }}>
      <div className="card-body">
        <div className="float float-right" style={{ width: 200 }}>
          <button type="button" className="btn btn-sm btn-secondary mb-1 w-100" onClick={loginAsUser(id)}>{t`Login as user`}</button>
        </div>

        <h5 className="card-title">{name}</h5>
        <h6 className="card-subtitle mb-2"><a className="text-muted" href={`mailto:${email}`}>{email}</a></h6>
      </div>
    </div>
  );
}