import React, { PureComponent } from 'react';

type RepeatableProps = {
  className?: string,
  disabled?: boolean,
  repeatDelay: number, // Wait before first action
  repeatInterval: number, // wait between actions
  onPress?: () => void,
  onHold?: () => void,
  onRelease?: () => void,

  children?: JSX.Element | JSX.Element[],
}

class Repeatable extends PureComponent<RepeatableProps> {
  repeatDelayTimer?: NodeJS.Timeout;
  repeatIntervalTimer?: NodeJS.Timeout;
  repeatAmount = 0;

  acquireTimer = () => {
    const repeatDelay = Math.max(Number(this.props.repeatDelay) || 0, 0);
    const repeatInterval = Math.max(Number(this.props.repeatInterval) || 0, 0);

    this.releaseTimer();

    this.repeatDelayTimer = setTimeout(() => {
      this.props.onHold?.();

      this.repeatIntervalTimer = setInterval(() => {
        if (typeof this.props.onHold === 'function') {
          this.props.onHold();
        }
      }, repeatInterval);
    }, repeatDelay);
  };

  releaseTimer = () => {
    if (this.repeatDelayTimer) {
      clearTimeout(this.repeatDelayTimer);
      this.repeatDelayTimer = undefined;
    }
    if (this.repeatIntervalTimer) {
      clearInterval(this.repeatIntervalTimer);
      this.repeatIntervalTimer = undefined;
    }
  };

  componentWillUnmount() {
    this.repeatAmount = 0;
    this.releaseTimer();
  }
  render() {
    const {
      disabled,
      repeatDelay,
      repeatInterval,
      onPress,
      onHold,
      onRelease,
      ...props
    } = this.props;

    const release = () => {
      this.repeatAmount = 0;
      this.releaseTimer();

      this.props.onRelease?.();
    };

    const press = (event: React.SyntheticEvent) => {
      event.persist();

      const releaseOnce = () => {
        document.documentElement.removeEventListener('mouseup', releaseOnce);
        release();
      };
      document.documentElement.addEventListener('mouseup', releaseOnce);

      this.props.onPress?.();

      this.acquireTimer();
    };

    return (
      <div
        role="presentation"
        {...props}
        onMouseDown={(event) => {
          if (disabled) { return; }
          press(event);
        }}
        onTouchStart={(event) => {
          if (disabled) { return; }
          press(event);
        }}
        onTouchCancel={() => {
          if (disabled) { return; }
          release();
        }}
        onTouchEnd={() => {
          if (disabled) { return; }
          release();
        }}
      />
    );
  }
}

export default Repeatable;
