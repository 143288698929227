import React, { useState, useRef, useEffect } from "react";
import { t } from "ttag";

import { Modal } from "./shared";

import Croppper from "cropperjs";
import "cropperjs/src/index.scss";

import { FaRedoAlt, FaUndoAlt } from 'react-icons/fa';

type Props = {
  id: string,
  imageSrc?: string,
  width: number,
  height: number,

  onCropped: (blob: Blob | null) => void,
  onCancel: () => void,
  onClose: () => void,
};

/*type CropState = {
  x: number,
  y: number,
  width: number,
  height: number
}*/

const ImageCropperComponent: React.FC<Props> = (props) => {
  const { id, imageSrc, onCropped } = props;
  const width = Math.round(props.width);
  const height = Math.round(props.height);

  const internalRef = useRef<HTMLImageElement>(null);
  const cropperRef = useRef<Cropper>();

  const minContainerHeight = window.innerHeight * .9 - 130; // 130px to account for header and footer
  const minContainerWidth = Math.min(window.innerWidth * .9, minContainerHeight * 1.6);

  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    if (!imageSrc) { return };
    const img = internalRef.current!;
    const aspectRatio = width / height;

    const options: Cropper.Options = {
      aspectRatio,
      minContainerHeight,
      minContainerWidth,

      viewMode: 2,
      dragMode: "move",
      autoCropArea: 1,
      movable: true,

      rotatable: true,
      scalable: true,
      checkOrientation: true,

      zoomable: false,
      toggleDragModeOnDblclick: false,

      crop: e => {
        // Round to prevent weird recursion errors
        let cropWidth = e.detail.width;
        let cropHeight = e.detail.height;

        if (cropWidth < width || cropHeight < height) {
          console.log({ cropWidth, cropHeight, width, height });
          cropper.setData({ width: Math.max(cropWidth, width), height: Math.max(cropHeight, height) });
        }
      },

      cropend: () => {
        const { x, y, width, height } = cropper.getData(true);
        console.log(x, y, width, height);
        setButtonEnabled(true);
      },
    }

    const cropper = new Croppper(img, options);
    cropperRef.current = cropper;
    // Trigger an initial crop sizing
    setTimeout(() => options.cropend!(null as any), 300);

    return () => { cropper.destroy() }; // Cleanup function

  }, [imageSrc, height, width, minContainerHeight, minContainerWidth, onCropped]);

  const close = () => document.getElementById(id)!.click();

  const finished = () => {
    setButtonEnabled(false);

    setTimeout(() => {
      const canvas = cropperRef.current!.getCroppedCanvas({
        height, width,
        imageSmoothingEnabled: true, imageSmoothingQuality: "high"
      });

      canvas?.toBlob(onCropped, "image/png");
      close();
      props.onClose();
    }, 100); // Small delay to have enough time to disable the button
  };

  const footer = <>
    <div>
      <button type="button" className="btn btn-danger"
        onClick={() => { close(); props.onCancel(); }}>{t`Cancel`}</button>
    </div>

    <div>
      <button type="button" className="btn btn-info"
        onClick={() => { cropperRef.current!.rotate(-90) }}><FaUndoAlt /></button>

      <button type="button" className="btn btn-info ml-1"
        onClick={() => { cropperRef.current!.rotate(90) }}><FaRedoAlt /></button>
    </div>

    <div>
      <button type="button" className="btn btn-primary"
        disabled={!buttonEnabled} onClick={finished}>{t`Crop image`}</button>
    </div>
  </>;

  return <Modal btnId={id} title={t`Crop image`} scrollable={false} footer={footer}
    maxWidth={minContainerWidth + 60} blockClosing bodyClass="d-flex justify-content-center">

    <img ref={internalRef} src={imageSrc} alt="..." style={{ display: "block", maxWidth: "100%" }} />
  </Modal>;
};

export default ImageCropperComponent;