import React, { Component } from "react";
import { t } from 'ttag';
import { toast } from "react-toastify";

import { fetchPost, setToken, handler, getLocale, unfocusAll } from "../util";
import { SubmitButton } from "./shared";

import { ReactComponent as CubLogo } from "../resources/logo_cub.svg";

type LoginProps = {
  updateHandler: (register?: boolean) => void,
}

type LoginState = {
  email?: string,
  password?: string,
  submitEnabled?: boolean,
}

export default class LoginComponent extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = { email: '', password: '', submitEnabled: true };
  }

  // Keep the state up to date with the fields values
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const t = event.target;
    const value = t.type === "checkbox" ? t.checked : t.value;
    const name = t.name;

    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    unfocusAll();
    this.setState({ submitEnabled: false });

    const s = this.state;
    let body = JSON.stringify({ email: s.email, password: s.password });

    try {
      const res = await fetchPost('/api/auth/login', body, false);
      if (res.ok) {
        const text = await res.clone().text(); // Clone because can't consume twice
        if (text.indexOf("__maintenance__") !== -1) {
          throw new Error(t`Webpage offline for maintenance. Please come back later`);
        }

        const json = await res.json();
        setToken(json.token);
        this.props.updateHandler();

      } else if (res.status === 401) {
        throw new Error(t`Invalid username or password`);
      } else {
        throw new Error(t`Error connecting to the server`);
      }
    } catch (e) {
      toast.error((e as any)?.message);
    } finally {
      this.setState({ submitEnabled: true });
    }
  }

  render() {
    return (
      <div className="card-body">
        <CubLogo height="70px" className="pb-3 mb-3" />

        <form action="." onSubmit={handler(this.handleSubmit)} method="post">
          <div className="form-group">
            <input type="email" autoComplete="email" required className="form-control text-light input-field" name="email"
              id="loginMail" value={this.state.email} onChange={this.handleChange} placeholder={t`Email address`} />
          </div>

          <div className="form-group">
            <input type="password" required className="form-control text-light input-field" name="password"
              autoComplete="current-password" id="loginPass" placeholder={t`Password`}
              value={this.state.password} onChange={this.handleChange} />
          </div>

          <a href="#f" className="text-light d-block pt-1" onClick={handler(() => {
            const email = prompt(t`Please enter your email`);
            if (!email || !email.trim()) { return; }

            fetchPost("/api/auth/forgot", JSON.stringify({ email, lang: getLocale() }))
              .then(p => toast.success(t`An email has been sent with the steps to reset the password`))
          })}>
            {t`Forgot your password?`}
          </a>

          <div className="d-flex justify-content-between pt-3">
            <button type="button" className="btn btn-secondary"
              onClick={() => this.props.updateHandler(true)}>
              {t`Register`}
            </button>

            <SubmitButton text={t`Login`} loadingText={t`Loading...`}
              enabled={!!this.state.submitEnabled} className="bg-dark btn-outline-primary" />
          </div>
        </form>
      </div>
    );
  }
}
