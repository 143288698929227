import React, { useState, useRef, useEffect } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import { SubmitButton, Progress } from "./shared";
import { fetchPost, handler, friendlyPassScore } from "../util";

type ResetPassProps = {
  email: string,
  token: string,

  onSuccess: () => void,
}

const ResetPassComponent: React.FC<ResetPassProps> = (props) => {
  const { email, token, onSuccess } = props;

  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [enabled, setEnabled] = useState(true);

  const passwordCheck = useRef<(pass: string, inputs: string[]) => number>();
  useEffect(() => {
    import("zxcvbn")
      .then(({ default: zxcvbn }) => {
        passwordCheck.current = (p, i) => zxcvbn(p, i).score;
      });
  }, []);
  const passScore = passwordCheck.current?.(pass, [email]) || 0;

  const strongPass = passScore >= 2;
  const passField = document.getElementById("pass")! as HTMLInputElement;
  passField?.setCustomValidity(strongPass ? "" : t`Password is not strong enough`);

  const passwordsSame = pass === confirmPass;
  const confirmPassField = document.getElementById("confirmPass") as HTMLInputElement;
  confirmPassField?.setCustomValidity(passwordsSame ? "" : t`The passwords don't match`);

  const submit = handler(async (e: React.FormEvent<HTMLFormElement>) => {
    setEnabled(false);
    const body = JSON.stringify({ email, token, password: pass, password_confirmation: confirmPass });
    const res = await fetchPost("/api/auth/reset", body);
    const { errors } = await res.json();

    if (res.ok) {
      toast.success(t`User password reset correctly`);
      onSuccess();

    } else {
      if (res.status === 400) {
        return toast.error(t`This password reset link has expired, please request a new one`);

      } else if (errors?.["password"]?.indexOf("validation.min.string") !== -1) {
        toast.error(t`Password is not strong enough`);
      } else if (errors?.["password"]?.indexOf("validation.confirmed") !== -1) {
        toast.error(t`The passwords don't match`);
      } else {
        toast.error(t`Unknown error`);
      }
    }

    setEnabled(true);
  });

  return <div className="card-body">
    <h3 className="card-title">{t`Reset Password`}</h3>
    <h5 className="card-title">{t`Email address` + ": " + email}</h5>

    <form action="." onSubmit={submit} method="post">
      <div className="form-group pb-1">
        <label htmlFor="pass">{t`New password`}</label>
        <input type="password" required className="form-control" name="pass"
          autoComplete="new-password" id="pass"
          value={pass} onChange={e => setPass(e.target.value)} />
      </div>

      <div className="password-meter pb-3 pl-4" style={{ width: "60%" }}>
        <Progress percent={Math.max(passScore * 25, 7)} />
        <small className="text-muted">{t`Password Strength:` + " " + friendlyPassScore(passScore)}</small>
      </div>

      <div className="form-group">
        <label htmlFor="confirmPass">{t`Confirm new password`}</label>
        <input type="password" required className="form-control" name="confirmPass"
          autoComplete="new-password" id="confirmPass"
          value={confirmPass} onChange={e => setConfirmPass(e.target.value)} />
      </div>

      <div className="d-flex justify-content-right pt-4">
        <SubmitButton text={t`Reset Password`} loadingText={t`Loading...`} enabled={enabled} />
      </div>
    </form>
  </div>
}

export default ResetPassComponent;
