import React, { useState, useEffect, useRef, CSSProperties } from "react";
import { t } from "ttag";
import Repeatable from "./Repeatable";
import Select from "react-select/creatable";

import { available_locales, getLocale, setLocale, handler, handleEvent, randId, fetchPost } from "../util";

export const LocaleSelector: React.FC<{ id?: string, style?: CSSProperties }> = props => {
  const { id, style } = props;
  const locale = getLocale();

  return <div id={id} style={style} className="dropup">
    <a className="btn btn-secondary dropdown-toggle" href="#k" role="button" data-toggle="dropdown">
      <span className={`flag-icon flag-icon-${locale}`} /> {available_locales[locale]}
    </a>
    <div className="dropdown-menu dropdown-menu-right">
      {
        Object.entries(available_locales).map(([code, name]) =>
          <a key={code} className="dropdown-item" href="#k"
            onClick={handler(() => setLocale(code))}>
            <span className={`flag-icon flag-icon-${code}`} /> {name}
          </a>
        )
      }
    </div>
  </div>;
}

type ProjectSelection = {
  label: string,
  value: string,
  enabled: boolean
}

type SelectProjectProps = {
  id?: string,
  options: ProjectSelection[],
  value?: ProjectSelection,
  onSelected: (p: ProjectSelection | null) => void,
  onCreated: (p: ProjectSelection) => void,
}

export const SelectProject: React.FC<SelectProjectProps> = props => {
  return <Select id={props.id} placeholder={t`Create or select the project`}
    options={props.options} value={props.value} isClearable maxMenuHeight={250}
    isOptionDisabled={o => o.enabled === false}
    formatCreateLabel={s => t`Create '${s}'`}
    onChange={async (e: any, { action }) => {
      if (!e) { return props.onSelected(null); }

      let pid: string;
      if (action === "create-option") {
        const body = JSON.stringify({ name: e.label });
        const res = await fetchPost("/api/project", body);
        const json = await res.json();
        pid = json.id;
        props.onCreated({ label: e.label, value: pid, enabled: true });
      } else {
        pid = e.value;
      }

      props.onSelected({ label: e.label, value: pid, enabled: true });
    }} />
}


type NumberInputProps = {
  default?: number,
  min?: number,
  max?: number,
  step?: number,

  small?: boolean,

  style?: React.CSSProperties,

  repeatDelay?: number,
  repeatInterval?: number,

  disabled?: boolean,

  validate?: (n: number) => boolean,

  onUnitChange: (n: number) => void,
}


export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const [num, setNum] = useState(props.default || 0)
  const step = props.step || 1;

  // Update the value when the default changes
  useEffect(() => {
    setNum(props.default || 0);
  }, [props.default]);

  const repeatDelay = props.repeatDelay || 300;
  const repeatInterval = props.repeatInterval || 70;

  const changeUnit = (n: number | "+" | "-") => {
    if (props.disabled) return;
    let newNum: number;
    if (n === "+") newNum = num + step;
    else if (n === "-") newNum = num - step;
    else newNum = n;

    newNum = props.max !== undefined ? Math.min(newNum, props.max) : newNum;
    newNum = props.min !== undefined ? Math.max(newNum, props.min) : newNum;

    if (!props.validate || props.validate(newNum)) {
      setNum(newNum);
      props.onUnitChange(newNum);
    } else {
      setNum(num);
    }
  }

  const dec = props.disabled ? undefined : () => changeUnit("-");
  const inc = props.disabled ? undefined : () => changeUnit("+");

  const className = props.small ? "input-group input-group-sm" : "input-group";

  return <div className={className} style={{ minWidth: 120, ...props.style }}>
    <Repeatable className="input-group-prepend" repeatDelay={repeatDelay}
      repeatInterval={repeatInterval} onHold={dec} onPress={dec}>
      <button type="button" style={{ minWidth: "2.4rem" }} disabled={props.disabled}
        className="btn btn-decrement bg-dark btn-outline-light" >
        <strong>-</strong>
      </button>
    </Repeatable>
    <input type="number" min={props.min} max={props.max} value={num} disabled={props.disabled}
      className="form-control hide-arrows" onChange={e => changeUnit(+e.target.value)} />
    <Repeatable className="input-group-append" repeatDelay={repeatDelay}
      repeatInterval={repeatInterval} onHold={inc} onPress={inc}>
      <button type="button" style={{ minWidth: "2.4rem" }} disabled={props.disabled}
        className="btn btn-increment bg-dark btn-outline-light">
        <strong>+</strong>
      </button>
    </Repeatable>
  </div>;
}

type ModalProps = {
  btnId?: string,
  modalId?: string,
  title: string,

  scrollable?: boolean,
  maxWidth?: number | string;
  height?: number | string;
  bodyClass?: string,
  blockClosing?: boolean,

  footer?: React.ReactNode,
  registerToggle?: (toggle: () => void) => void,

  children: JSX.Element | JSX.Element[],
}

export const Modal: React.FC<ModalProps> = props => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { btnId, title, footer, registerToggle, scrollable, maxWidth, height, blockClosing, bodyClass } = props;
  const modalId = props.modalId || randId();

  registerToggle?.(() => buttonRef.current!.click());

  const scrollClass = scrollable === undefined || scrollable ? "modal-dialog-scrollable" : "";
  const btnData = blockClosing ? { "data-backdrop": "static", "data-keyboard": "false" } : {};

  return <>
    <button id={btnId} type="button" className="d-none" ref={buttonRef}
      data-toggle="modal" data-target={`#${modalId}`} {...btnData} />

    <div className="modal fade text-dark" id={modalId} tabIndex={-1} role="dialog">
      <div className={`modal-dialog ${scrollClass}`} role="document" style={{ maxWidth, height }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            {!blockClosing && <button type="button" className="close" data-dismiss="modal">
              <span>&times;</span>
            </button>}
          </div>
          <div className={`modal-body ${bodyClass || ""}`}>
            {props.children}
          </div>

          {footer && <div className="modal-footer justify-content-between">{footer}</div>}
        </div>
      </div>
    </div>
  </>;
}

const colorPercent = (p: number) => {
  if (p <= 25) return "bg-danger";
  if (p <= 50) return "bg-warning";
  if (p <= 75) return "bg-info";
  return "bg-success";
}

export const Progress: React.FC<{ percent: number }> = props => {
  return <div className="progress">
    <div className={`progress-bar progress-bar-striped ${colorPercent(props.percent)}`} role="progressbar" style={{ width: `${props.percent}%` }}></div>
  </div>;
}

type DraggableProps = {
  initialX: number,
  initialY: number,
  maxX: number,
  maxY: number,
  width: number,
  height: number,
  scale: number,
  onDragEnd: (x: any, y: any) => void,
  children: (childrenProps: DragChildProps) => JSX.Element
}

type DragChildProps = {
  x: number,
  y: number,
  ref: React.RefObject<RefElem>,
  events: {
    onMouseDown?: (e: DragEvent) => void,
    onMouseUp?: (e: DragEvent) => void,
    onMouseMove?: (e: DragEvent) => void,
    onMouseLeave?: (e: DragEvent) => void,
  }
}

type RefElem = SVGImageElement;
type DragElem = SVGGElement;
type DragEvent = React.MouseEvent<DragElem, MouseEvent>;

export const Draggable: React.FC<DraggableProps> = (props) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const ref = useRef<RefElem>(null);

  // Reset the offset when the prop arguments change
  useEffect(() => {
    setOffset({ x: 0, y: 0 });
  }, [props.initialX, props.initialY]);

  const [dragging, setDragging] = useState(false);

  // calculate relative position to the mouse and set dragging=true
  const onMouseDown = handleEvent((e: DragEvent) => {
    // only left mouse button
    if (e.button !== 0) return
    setDragging(true);
    setOffset({ x: e.clientX, y: e.clientY });
  });

  const calculatePos = (e: DragEvent) => {
    const { maxX, maxY, width, height } = props;

    let x = Math.round(props.initialX + (e.clientX - offset.x) / props.scale);
    let y = Math.round(props.initialY + (e.clientY - offset.y) / props.scale);

    /* Admitimos que se salga hasta un tercio de la imagen por los lados  y por arriba.
    Para no permitirlo: x: Math.max(0, maxX ? Math.min(maxX - width, x) : x),
    */
    const block = [maxX / 2 - maxX / 9, maxY - maxY / 7, maxX / 4.5, maxY / 7];

    // Detect collision between bounding boxes
    if (x < block[0] + block[2] &&
      x + width > block[0] &&
      y < block[1] + block[3] &&
      y + height > block[1]) {

      // collision detected! Calculate side and adjust position,
      // using minkowski sum and checking where the center of it
      // lies with regards to the diagonals
      const cy = (y + height / 2) - (block[1] + block[3] / 2);
      const wy = (width + block[0]) * cy; // (A.width + B.width) * (A.centerY - B.centerY)

      const cx = (x + width / 2) - (block[0] + block[2] / 2);
      const hx = (height + block[1]) * cx; // (A.height + B.height) * (A.centerX - B.centerX)

      if (wy > hx) {
        if (wy > -hx) {
          // Bottom Collision
          y = block[1] - height;
          console.log("B");
        } else {
          // Left Collision
          x = block[0] - width;
          console.log("L");
        }
      } else {
        if (wy > -hx) {
          // Right Collision
          x = block[0] + block[2];
          console.log("R");
        } else {
          // Top Collision
          y = block[1] - height;
          console.log("T");
        }
      }
    }

    return {
      x: Math.max(-width / 3, (maxX ? Math.min(maxX - 2 * width / 3, x) : x)),
      y: Math.max(-height / 3, (maxY ? Math.min(maxY - 2 * height / 3, y) : y)),
    };
  };

  const onMouseUp = handleEvent((e: DragEvent) => {
    if (!dragging) return;

    setDragging(false);
    const { x, y } = calculatePos(e);
    props.onDragEnd(x, y);
  });

  const onMouseMove = handleEvent((e: DragEvent) => {
    if (!dragging) return;

    const elem = ref.current;
    if (elem) {
      const { x, y } = calculatePos(e);
      elem.setAttribute("x", x + "");
      elem.setAttribute("y", y + "");
    }
  });

  return props.children({
    x: props.initialX, y: props.initialY, ref,
    events: { onMouseDown, onMouseUp, onMouseMove, onMouseLeave: onMouseUp }
  });
}


type SubmitButtonProps = {
  enabled: boolean,
  className?: string,
  text: string,
  loadingText: string,
}

export const SubmitButton: React.FC<SubmitButtonProps> = (props) => {
  const { enabled, className } = props;

  return (
    <button type="submit" className={`btn btn-primary ${className}`} disabled={!enabled}>
      <SubmitButtonInternal {...props} />
    </button>
  );
}

const SubmitButtonInternal: React.FC<SubmitButtonProps> = (props) => {
  const { enabled, text, loadingText } = props;

  if (enabled) {
    return <span>{text}</span>;
  } else {
    return (
      <div className="spinner-border spinner-border-sm text-light" role="status">
        <span className="sr-only">{loadingText}</span>
      </div>
    );
  }
}

type UseImageStatus = "loading" | "loaded" | "failed";
type UseImageType = {
  image?: HTMLImageElement,
  status: UseImageStatus
};
const DefImageState: UseImageType = { image: undefined, status: "loading" };

export function useImage(url: string, done?: () => void)
  : [HTMLImageElement | undefined, UseImageStatus] {

  const [{ image, status }, setState] = useState(DefImageState);

  useEffect(() => {
    if (!url) return;
    const img = document.createElement("img");

    function onload() {
      setState({ image: img, status: "loaded" });
      done?.();
    }

    function onerror() {
      setState({ image: undefined, status: "failed" });
      done?.();
    }

    img.addEventListener("load", onload);
    img.addEventListener("error", onerror);
    img.src = url;

    return function cleanup() {
      img.removeEventListener("load", onload);
      img.removeEventListener("error", onerror);
      setState(DefImageState);
    };
  },
    [url, done]
  );

  // return array because it it better to use in case of several useImage hooks
  // const [background, backgroundStatus] = useImage(url1);
  // const [patter] = useImage(url2);
  return [image, status];
};
