import React, { useState, useMemo, useEffect } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

import { ImageFile } from "../types";
import { handler } from "../util";

const baseStyle: React.CSSProperties = {
  display: 'flex',
  padding: '20px',
  border: "2px dashed #bdbdbd",
  borderRadius: 5,
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
  backgroundColor: 'rgba(0,0,0, 0.2)'
};
const acceptStyle = { borderColor: '#00e676', color: '#00e676' };
const rejectStyle = { borderColor: '#ff1744', color: '#ff1744' };

type Props = {
  id?: string,
  className: string,

  minHeight?: number,
  minWidth?: number,

  maxSizeMB: number,

  alwaysUpload?: boolean,

  onFileSelected: (file?: ImageFile | string) => void,
  currentFile?: ImageFile | string,

  children?: JSX.Element | JSX.Element[],
};

//// Make sure to revoke the data uri to avoid memory leaks
// URL.revokeObjectURL(file.preview);

const ImageUploaderComponent: React.FC<Props> = (props) => {
  const { maxSizeMB } = props;
  const maxSizeKB = maxSizeMB * 1024 * 1024;
  const [file, _setFile] = useState(props.currentFile);

  // Reload the file on prop changes
  useEffect(() => _setFile(props.currentFile), [props.currentFile]);

  const setFile = (file?: ImageFile | string) => {
    _setFile(file);
    props.onFileSelected(file);
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      multiple: false,
      maxSize: maxSizeKB,
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
        "image/gif": [".gif"],
      },
      onDropAccepted: files => {
        if (files.length !== 1) { return; }

        const f = files[0];
        const url = URL.createObjectURL(f);

        const image = new Image();
        image.addEventListener('load', function () {
          const minHeight = Math.round(props.minHeight || 0);
          const minWidth = Math.round(props.minWidth || 0);

          if ((minHeight && image.height < minHeight)
            || (minWidth && image.width < minWidth)) {
            toast.error(t`The image is not big enough (Min size: ${minWidth} x ${minHeight})`)
          } else {
            setFile({ content: f, ext: f.name.split(".").pop() || "noext", preview: url });
          }

        });
        image.src = url;
      },

      onDropRejected: f => {
        toast.error(t`Invalid file format or file too big (Max ${maxSizeMB} MB)`);
      }
    });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [isDragAccept, isDragReject]);

  if (!file || props.alwaysUpload) {
    return (
      <section id={props.id} className={props.className}>
        <div {...getRootProps({ style })} >
          <input {...getInputProps()} />
          <p className="m-auto">{
            isDragReject
              ? t`Invalid file format or file too big (Max ${maxSizeMB} MB)`
              : t`Drag an image or click to select`
          }</p>
        </div>
      </section>
    );
  } else {
    return (
      <section id={props.id} className={`d-flex ${props.className}`}>
        <div className="mr-3">
          <div className="m-2 bg-secondary" style={{
            border: "1px solid #788", width: 160, cursor: "pointer"
          }} onClick={() => setFile(file)}>
            <img src={typeof file === "string" ? file : file.preview}
              alt="..." style={{ maxHeight: "100%", maxWidth: "100%" }} />
          </div>

          <a href="#a" style={{ display: "block", textAlign: "center" }}
            onClick={handler(() => setFile(undefined))}>{t`Delete / Change image`}</a>
        </div>
        <div>
          {props.children}
        </div>
      </section>
    );
  }
}

export default ImageUploaderComponent;
